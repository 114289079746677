<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      v-if="!isLoading && elementData === null"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_single_data')}}
      </h4>
      <div class="alert-body">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.check')}}
        <b-link
          class="alert-link"
          :to="{ name: 'auth-final-list'}"
        >
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.list')}}
        </b-link>
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.to_find_other')}}
      </div>
    </b-alert>


    <template>
      <b-card class="bg-white">
        <div class="text-primary h2">
          <feather-icon
            icon="StarIcon"
            size="25"
            class="mr-0 mr-sm-50"
            variant="primary"
          />
            <span class="d-none d-sm-inline"> Paciente: {{ Name }}</span>
        </div>
      </b-card>
      <div v-for="(element, index) in elementData" :key="index">
        <element-edit-tab
          :element-data="element"
          :index="index"
          :is-saving="savingStates[index]"
          @save-element="saveElement"
          :clinical-laboratory-list="clinicalLaboratoryList"
          class="mt-2 pt-75"
        />
      </div>
    </template>
    
  </div>
</template>

<script>
import {
   BAlert, BLink, BCard, BSpinner
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import elementStoreModule from '../storeModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ElementEditTab from './EditCard.vue'
import moment from 'moment'

export default {
  components: {
    BAlert,
    BSpinner,
    BLink,
    BCard,
    ElementEditTab: ElementEditTab,
  },
  data() {
    return {
      ARL_CODES_APP_STORE_MODULE_NAME: 'app-vaccine-schema',
      RESOURCES_ELEMENT_NAME: 'vaccine',
      elementData: [],
      clinicalLaboratoryList: [],
      savingStates: [],
      Name: '',
      isLoading: true,
      errorLoadingData: false,
    }
  },
  async mounted() {
    if (!store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) {
      store.registerModule(this.ARL_CODES_APP_STORE_MODULE_NAME, elementStoreModule);
    }
    this.savingStates = this.elementData.map(() => false);

    try {
      this.elementData = await this.fetchElement();
      this.Name =
        this.elementData && this.elementData.length > 0 && this.elementData[0].medical_order
          ? this.elementData[0].medical_order.patient_name || ''
          : '';
      this.clinicalLaboratoryList = (
        await this.fetch_companies()
      ).filter((element) => element.supplier_type_key === 'VACCINE');
    } catch (error) {
      this.errorLoadingData = true;
    } finally {
      this.isLoading = false;
    }
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ARL_CODES_APP_STORE_MODULE_NAME)
  },
  methods: {
    async fetch_key_value(type, deep, parent) {
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_key_value', { type: type, deep: deep, parent: parent }).then(response => {
        var list = response.data.items
        list.unshift({ id: -1, key: this.$t('list.defaultOption'), value: this.$t('list.defaultOption') })
        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return []
        }
      })
    },
    async fetchElement() {
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_element', { id: router.currentRoute.params.id })
        .then(response => { 
          return response.data 
        })
        .catch(error => {
          return undefined
        })
    },
    async fetch_companies() {
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_companies', {
          queryParams: {
            perPage: 10000,
            page: 1,
            query: '',
            companyType: 'SUPPLIER'
          }
        })
        .then(response => {
          return response.data.items
        })
        .catch(() => {
          return []
        })
    },
    async addFile(fileData, prst_uuid, camo_id) {
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/addFile', { fileData: fileData, prst_uuid: prst_uuid, camo_id: camo_id })
      .then(response => {
        return response.data
      }).catch(error => {
        console.log(error)
        return undefined
      }) 
    },
    async saveElement(index, data) {
      this.$set(this.savingStates, index, true); 
      const method = 'app-vaccine-schema/save';
      let error = false;

      try {
        await this.addFileLogic(data);

        const response = await store.dispatch(method, { elementData: data }).catch(() => false);

        if (!response) {
          error = true;
          throw new Error('Error al guardar el esquema de vacunación');
        }

        this.showToast(
          `El esquema de vacunación se guardó correctamente`,
          'success',
          'Adjunto anexado',
          'SaveIcon'
        );
      } catch (e) {
        error = true;
        console.error(e);
        this.showToast(
          `Error al guardar el esquema de vacunación`,
          'danger',
          'Error registrando el adjunto',
          'ErrorIcon'
        );
      } finally {
        this.$set(this.savingStates, index, false);
      }

      if (error) {
        this.showToast(
          this.$t(this.RESOURCES_ELEMENT_NAME + '.action_results.update.failure'),
          'danger',
          this.$t(this.RESOURCES_ELEMENT_NAME + '.action_results.update.title'),
          'SaveIcon'
        );
      } else {
        this.showToast(
          this.$t(this.RESOURCES_ELEMENT_NAME + '.action_results.update.success'),
          'success',
          this.$t(this.RESOURCES_ELEMENT_NAME + '.action_results.update.title'),
          'SaveIcon'
        );
      }
    },
    async addFileLogic(data) {
      const fileFields = [
        { fileKey: 'first_carnet_file', idKey: 'first_carnet_id', key: 'Primera dosis' },
        { fileKey: 'second_carnet_file', idKey: 'second_carnet_id', key: 'Segunda dosis' },
        { fileKey: 'third_carnet_file', idKey: 'third_carnet_id', key: 'Tercera dosis' },
        { fileKey: 'fourth_carnet_file', idKey: 'fourth_carnet_id', key: 'Cuarta dosis' },
        { fileKey: 'antibody_carnet_file', idKey: 'antibody_carnet_id', key: 'Anticuerpo' },
      ];

      for (const { fileKey, idKey, key } of fileFields) {
        if (data[fileKey]) {
          const originalFile = data[fileKey];
          const newFile = new File([originalFile], `${originalFile.name}-${key}`);
          const formData = new FormData();
          formData.append('file', newFile);

          const response = await this.addFile(formData, data.medical_order.step.uuid, data.medical_order.id);
          if (response) {
            data[idKey] = response;
            data[fileKey] = null;
          } else {
            throw new Error('Error al procesar archivo');
          }
        }
      }
    },
    showToast(message, variant, title, icon) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: title,
          text: message,
          icon: icon,
          variant: variant,
        },
      });
    },
  }
}
</script>

<style>

</style>
