<template>
  <div>
    <b-form @submit.prevent="save">
      <validation-observer ref="elementForm" #default="{ invalid }">
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary"
              />
              <span class="d-none d-sm-inline">Esquema de vacunación</span>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row class="mb-1">
              <b-col cols="4">
                <feather-icon icon="StarIcon" class="mr-75"/>
                <span class="font-weight-bold mr-1">{{ $t(resourcesElementName + '.object.number') }}</span>
                <span>{{ elementData.medical_order.id }}</span>
              </b-col>

              <b-col cols="8">
                <feather-icon icon="StarIcon" class="mr-75"/>
                <span class="font-weight-bold mr-1">{{ $t(resourcesElementName + '.object.service') }}</span>
                <span>{{ getServiceName() }}</span>
              </b-col>
            </b-row>

            <b-row class="mb-1">
              <b-col cols="4">
                <feather-icon icon="StarIcon" class="mr-75"/>
                <span class="font-weight-bold mr-1">{{ $t(resourcesElementName + '.object.scheme') }}</span>
                <span>{{ getScheme() }}</span>
              </b-col>
            </b-row>

            <b-row class="mb-1">
              <b-col cols="1">
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.dosis') }}</span>
              </b-col>
              <b-col cols="1">
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.proposed_date') }}</span>
              </b-col>
              <b-col cols="1">
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.status') }}</span>
              </b-col>
              <b-col cols="2">
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.application_date') }} <span class="text-danger">*</span></span>
              </b-col>
              <b-col cols="3">
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.laboratory') }} <span class="text-danger">*</span></span>
              </b-col>
              <b-col cols="1">
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.charge') }}</span>
              </b-col>
              <b-col cols="2">
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.carnet') }}</span>
              </b-col>
            </b-row>

            <div v-for="index in indexes">
              <div v-if="elementData[`${index}_proposed_application_date`]">
                <dosis-registry
                  :element-data="elementData"
                  :clinical-laboratory-list="clinicalLaboratoryList"
                  :index="index"
                  :disable-selectors="disableFields[index]"
                />
              </div>

            </div>

            <b-row>
              <b-col cols="12" md="12">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.observations') }}</span>

                <b-form-textarea
                  id="observations"
                  v-model="elementData.observations"
                  maxlength="500"
                  max="500"
                />
              </b-col>
            </b-row>

          </b-card-body>
        </b-card>

        <b-row class="d-flex justify-content-end">
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="router().go(-1)"
          >
            {{ $t('actions.back') }}
          </b-button>
          <b-button
            variant="primary"
            type="submit"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :disabled="invalid || isSaving"
            @click="save"
          >
            <span v-if="isSaving">
              <b-spinner small class="mr-2"></b-spinner>
            </span>
            <span v-else>
              {{ $t('actions.save') }}
            </span>
          </b-button>
        </b-row>
      </validation-observer>
    </b-form>

  </div>

</template>

<script>
import {
  BFormTextarea, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BForm, BSpinner
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import moment from 'moment'
import router from '@/router'
import DosisRegistry from './DosisRegistry.vue'

export default {
  components: {
    DosisRegistry, BFormTextarea, BCard, BButton, BRow, BCol, BCardHeader, BCardBody, BForm, ValidationObserver, BSpinner,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    clinicalLaboratoryList: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resourcesElementName: 'vaccine',
      required,
      indexes: ['first', 'second', 'third', 'fourth', 'antibody'],
      vaccineSchemesList: [{ id: 'FIRST', value: '0d, 1m, 6m' }, { id: 'SECOND', value: '0d, 7d, 28d, 6m' }, { id: 'OTHER', value: 'Otro' }],
      disableFields: {},
      invalid: false,
    }
  },
  mounted() {
    this.getSelectorData()
  },
  methods: {
    getSelectorData() {
      this.indexes.forEach(index => {
        const applicationDate = this.elementData[`${index}_application_date`];
        const clinicalLaboratoryId = this.elementData[`${index}_clinical_laboratory_id`];
        this.$set(this.disableFields, index, applicationDate !== null && clinicalLaboratoryId !== null);
      });
    },
    router() {
      return router
    },
    getScheme() {
      return this.elementData.vaccine_scheme_id ? this.vaccineSchemesList.find(element => element.id == this.elementData.vaccine_scheme_id).value : ''
    },
    getServiceName() {
      const { service } = this.elementData.medical_order.bundles[0].service_bundle_group.services[0]
      return `${service.cups} ${service.cups_name}`
    },
    processStatus(field) {
      return this.$t(`${this.resourcesElementName}.status.${field ? 'applied' : 'pending'}`)
    },
    formatDate(value) {
      return moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY')
    },
    save() {
      if (this.isSaving) return;
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          this.$emit('save-element', this.index, this.elementData);
        }
      });
    },
  },
}
</script>

<style>

</style>
